<template>
	<div class="border2">
		<div class="bgC8 pdtb10 pdl32">
			<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
				<span class="fontC2 font16">{{title}}</span>
			</el-checkbox>
		</div>
		<div class="pdl32 a-flex-rfsfs a-flex-wrap">
            <div class="a-ptb-18 w16p " v-for="(item, index) in dataList" :key="index">
                <el-tree
                    :ref="('tree'+item[0].id)"
                    :data="item"
                    show-checkbox
                    node-key="id"
                    default-expand-all
                    @check="handleCheckChange"
                    :default-expanded-keys="checkedKeysDefault[item[0].id]"
                    :default-checked-keys="checkedKeysDefault[item[0].id]"
                    :props="defaultProps">
                </el-tree>
            </div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			title: {
				type: String
			},
			type: {
				default: 'web'
			},
			list: {
				default: []
			},
			companyId: {
				type: Number,
				required: true
			}
		},
		data() {
			return {
				isIndeterminate: false,
				checkAll: false,
				checkedMenus: [],
				dataList: [],
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                appidDic:{
                    'web': 'hichar.company.web.jd',
                    'wx': 'hichar.company.wxapp.jd',
                    'devops': 'hichar.devops.wxapp.jd',
                    'engineer': 'hichar.engineer.wxapp.jd',
                },
                checkedKeysAll: {},//所有的菜单选中状态
                checkedKeysDefault: {},//所有的菜单默认选中状态
			}
		},
		created() {
            this.getRoleMenu()
		},
		methods: {
			handleCheckAllChange(value) {
				if (value) {
                    this.dataList.map(item=>{
                        this.checkedKeysAll[item[0].id] = item[0].children.map(ite=>{
                            return ite.id
                        })
                        this.checkedKeysAll[item[0].id].push(item[0].id)
                        let ref = 'tree' + item[0].id
                        this.$refs[ref][0].setCheckedKeys(this.checkedKeysAll[item[0].id]);
                    })
					this.isIndeterminate = false
				} else {
					this.dataList.map(item=>{
                        this.checkedKeysAll[item[0].id] = []
                        let ref = 'tree' + item[0].id
                        this.$refs[ref][0].setCheckedKeys([]);
                    })
				}
                this.$emit('input', this.checkedKeysAll)
			},
            handleCheckChange (data, checked) {
                let { checkedKeys, halfCheckedKeys } = checked
                let  nodeKeys = checkedKeys.concat(halfCheckedKeys)
                if(data.parentId){
                    this.checkedKeysAll[data.parentId] = nodeKeys
                }else if(!data.parentId) {
                    this.checkedKeysAll[data.id] = nodeKeys
                }
                this.$emit('input', this.checkedKeysAll)
            },
			//获取web权限
			getRoleMenu () {
                this.$Axios._post({
					url: this.$Config.apiUrl.getMenuByCompanyId,
					params: {
						companyId: this.companyId,
                        appId: this.appidDic[this.type] 
					}
				}).then(({
					data
				}) => {
					this.dataList = data.map(item=>{
                        return [item]
                    })
                    if(this.list) {
                        this.checkedKeysDefault = JSON.parse(JSON.stringify(this.list))
                        this.checkedKeysAll = JSON.parse(JSON.stringify(this.list))
                        for (const key in this.checkedKeysDefault) {
                            if(this.checkedKeysDefault[key].length > 1) {
                                this.checkedKeysDefault[key].splice(this.checkedKeysDefault[key].indexOf(key), 1)
                            }
                        }
                    }
				})
            },
		}
	}
</script>

<style lang="scss" scoped>
</style>
